<template>
  <div>
    <AddCsvFile />
  </div>
</template>

<script>
import AddCsvFile from '@/components/AddCsvFile.vue';

export default {
  components: {
    AddCsvFile
  }
}
</script>

<style>
/* Add your styles here */
</style>
